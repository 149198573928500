<template>
  <div class="row login w-100 h-100">
    <div class="col-12 my-auto">
      <b-card class="form-signin text-center shadow m-auto">
        <b-img :src="logo" height="112" blank-color="#ffffff" class="mt-3 mb-4"></b-img>
        <form @submit.prevent="logout" class="form-signin">
          <div class="form-group text-center">
            Logging out...<br/><b-link to="/login">Login</b-link>
          </div>
        </form>
      </b-card>
    </div>
  </div>
</template>

<script>
import { auth } from "@/helpers/auth";

export default {
  data () {
    return {
      account: null, 
      title: process.env.VUE_APP_TITLE,
      logo: require('../assets/logo_rgb.png')
    }
  },
  async mounted() {
    this.account = auth.getAccount();
    if(this.account) { this.logout() }
  },
  methods: {
    logout() {
      auth.logout();
    },
  }
};
</script>

<style scoped>
.form-signin {
  width: 100%;
  max-width: 380px;
  background: #e2e2e2;
}
</style>
